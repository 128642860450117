/**
 * Modal
 * @module components/Modal
 */

import { useContext } from 'react';
import { LocalizationContext } from 'context/localization.context';
import Icon from 'components/ui/Icon';
import { toast } from 'react-toastify';
import AriaModal from 'react-aria-modal';
import clsx from 'clsx';
import IconExternalLink from '../Icon/icons/IconExternalLink';
import IconSwitchLink from '../Icon/icons/IconSwitchLink';
import IconLink from '../Icon/icons/IconLink';

interface ModalModel {
	roundedCorners?: boolean;
	toggleModal: () => void;
	width: 'large' | 'medium' | 'small' | 'max';
	title: string;
	heading: string;
	children: JSX.Element | JSX.Element[];
	hasBreadcrumbs?: boolean;
	itemCode?: string;
	copyUrl?: string;
}

const Modal = ({
	toggleModal,
	width,
	title,
	heading,
	children,
	roundedCorners,
	hasBreadcrumbs = false,
	itemCode,
	copyUrl,
}: ModalModel) => {
	const { t }: any = useContext(LocalizationContext);
	const copyItemCode = () => {
		if (copyUrl) {
			navigator.clipboard.writeText(copyUrl);

			toast(t('productdetailpage/linkcopied'), {
				type: toast.TYPE.SUCCESS,
			});
		}
	};
	return (
		<AriaModal
			onExit={toggleModal}
			titleText={title ? title : 'Modal'}
			underlayClickExits={false}
			focusDialog={true}
			verticallyCenter={true}
			focusTrapPaused={true}
			underlayClass="overlay"
			dialogStyle={{ width: '100%' }}
		>
			<div
				className={clsx(
					'h-full w-full max-w-297.5 bg-white mx-auto',
					width === 'large'
						? 'w-full lg:max-w-300'
						: width === 'medium'
						? 'w-full lg:max-w-200'
						: width === 'small'
						? 'w-full lg:max-w-150'
						: width === 'max'
						? 'lg:max-w-90vw'
						: 'w-full',
					roundedCorners && 'rounded-lg'
				)}
			>
				<div
					className={clsx(
						'h-16 w-full bg-blue px-8 relative smMax:h-auto',
						roundedCorners && 'rounded-t-lg'
					)}
				>
					<p
						className={'text-p pt-5 pb-4 text-white font-semibold inline-block'}
					>
						{heading}
					</p>
					{itemCode && copyUrl && (
						<span
							className="text-white"
							onClick={() => {
								copyItemCode();
							}}
						>
							{' '}
							-
							<IconLink className="p-1 cursor-pointer" />
						</span>
					)}
					<a
						className="absolute right-0 top-0 mt-2.5 mr-4.5 w-11 h-11 py-1.5 px-2.5 pointer-events-auto no-underline opacity-80 outline-none hover:opacity-100 focus:outline-white"
						href="#"
						title={t('shared/modal/closemodal')}
						onClick={(e) => {
							e.preventDefault();
							toggleModal();
						}}
					>
						<Icon icon="close" size={2} color="#ffffff" />
					</a>
				</div>
				<div
					className={clsx(
						hasBreadcrumbs ? 'md:pt-0 pt-0' : 'md:pt-8 pt-4',
						'px-4 pb-4 md:px-8 md:pb-8'
					)}
				>
					{children}
				</div>
			</div>
		</AriaModal>
	);
};

export default Modal;
