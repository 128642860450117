/**
 * MenuOptions
 */

import CustomerModal from 'components/ui/CustomerModal';
import FilterField from 'components/ui/FilterField';
import IconSelect from 'components/ui/IconSelect';
import Modal from 'components/ui/Modal';
import { SearchInput } from 'pages/SearchPage/SearchPage.styles';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	FetchUser,
	selectUserData,
	selectUserObject,
	UpdateProjects,
	UpdateWarehouses,
} from 'store/modules/user';

interface Props {
	/** Tooltip for warehouse select */
	warehouseTooltip?: string;
}

/** Main description for this component. */
const MenuOptions = ({ warehouseTooltip }: Props): JSX.Element => {
	const dispatch = useDispatch();
	const { customers, projects, warehouses } = useSelector(selectUserObject);
	const { customerLoading, userObject } = useSelector(selectUserData);
	const [customerSearch, setCustomerSearch] = useState(false);

	const [isSalesUser, setIsSalesUser] = useState(false);

	useEffect(() => {
		userObject.salesUser && setIsSalesUser(true);
	}, [userObject]);

	const selectedCustomer = customers?.filter(
		(item: any) => item.selected === true
	)[0];

	const selectedProject = projects?.filter(
		(item: any) => item.selected === true
	)[0];

	const selectedWarehouse = warehouses?.filter(
		(item: any) => item.selected === true
	)[0];

	const handleCustomerChange = async (selectedItem: any) => {
		await dispatch(FetchUser(selectedItem.id));
	};

	const handleProjectChange = async (selectedItem: any) => {
		await dispatch(UpdateProjects(selectedItem.id));
	};

	const handleWarehouseChange = async (selectedItem: any) => {
		await dispatch(UpdateWarehouses(selectedItem.id));
	};

	return (
		<div>
			{customerSearch && (
				<CustomerModal
					onChange={handleCustomerChange}
					onToggle={() => setCustomerSearch(false)}
				/>
			)}

			{customers && customers.length > 0 && (
				<IconSelect
					id="customers"
					icon="user"
					selected={selectedCustomer}
					items={customers}
					onChange={handleCustomerChange}
					salesUser={isSalesUser}
					handleOpenCustomerSearch={() => setCustomerSearch(!customerSearch)}
				/>
			)}
			{projects && projects.length > 0 && (
				<IconSelect
					id="projects"
					icon="archive"
					selected={selectedProject}
					items={projects}
					onChange={handleProjectChange}
					loading={customerLoading}
				/>
			)}
			{warehouses && warehouses.length > 0 && (
				<IconSelect
					id="warehouses"
					icon="mapPin"
					selected={selectedWarehouse}
					items={warehouses}
					onChange={handleWarehouseChange}
					warehouseTooltip={warehouseTooltip}
					loading={customerLoading}
				/>
			)}
		</div>
	);
};

export default MenuOptions;
