/**
 * QuickImportArticlesModal
 */

import clsx from 'clsx';
import { useContext, useEffect, useMemo, useState } from 'react';
import Button from '../Button';
import Icon from '../Icon';
import Modal from '../Modal';
import Tooltip from '../Tooltip';
import { useTable } from 'react-table';
import { quickAddValidateCode } from 'api/quickadd';
import { DictionaryModel } from 'types/common';
import { AddToCartModel } from 'types/cart-types';
import {
	GenericWarning,
	parseAndFormatRows,
	QuickImportArticlesError,
	ServerError,
} from 'common/helpers';
import { useDispatch } from 'react-redux';
import { AddToCart } from 'store/modules/cart';
import { toast } from 'react-toastify';
import { Int32MaxValue } from 'common/constants';
import { LocalizationContext } from 'context/localization.context';

interface Props {
	/** Pass a toggle modal callback function */
	onToggle: Function;

	api: DictionaryModel;

	sortSelection: string;
}

interface ArticleProps {
	/** Alias code of an article */
	code: string;

	/** Item code of an article */
	itemCode?: string;

	/** Selected quantity of an article */
	quantity?: number;

	/** If the entered article code is valid */
	isValid?: boolean;

	/** The name of an article */
	name?: string;
}

/** Main description for this component. */
const QuickImportArticlesModal = ({
	onToggle,
	api,
	sortSelection,
}: Props): JSX.Element => {
	const { t }: any = useContext(LocalizationContext);
	const dispatch = useDispatch();
	const [inputValue, setInputValue] = useState('');
	const [articles, setArticles] = useState<ArticleProps[]>([]);
	const [newRowAdded, setNewRowAdded] = useState<boolean>(false);
	const [quantities, setQuantities] = useState<number[]>([]);
	const [isValidating, setIsValidating] = useState<boolean>(false);

	const clearInput = () => {
		setInputValue('');
	};

	useEffect(() => {
		const qtys = articles.map((item: ArticleProps) => item.quantity as number);
		setQuantities(qtys);
	}, [articles]);

	const handlePaste = async (e: any) => {
		const clipboardData = e.clipboardData || (window as any).clipboardData;
		const value = clipboardData.getData('Text');
		//setArticles([]);
		if (value !== '') {
			let codesAndQuantity = parseAndFormatRows(value);
			setIsValidating(true);

			try {
				const data = await quickAddValidateCode(
					api.validateCodeUrl,
					codesAndQuantity
				);
				setArticles([...articles, ...data]);
			} catch (error) {
				console.log(error);
			} finally {
				setIsValidating(false);
			}
		}
		setInputValue('');
	};

	const data = useMemo(() => articles, [articles]);
	const columns: any = useMemo(
		() => [
			{ Header: t('quickadd/itemcode'), accessor: 'itemCode' },
			{ Header: t('quickadd/name'), accessor: 'name' },
			{ Header: t('quickadd/quantity'), accessor: 'quantity' }, // even though quantity may exist on the article it's handled separatly to facilitate better UX.
		],
		//eslint-disable-next-line
		[]
	);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
	} = useTable({
		columns,
		data,
	});

	const handleAddRow = () => {
		setArticles([
			...articles,
			{
				code: '',
			},
		]);

		setQuantities([...quantities, 1]);
		setNewRowAdded(true);
	};

	const handleInputChange = (e: any, cell: any) => {
		let value;
		switch (cell.column.id) {
			case 'quantity':
				value = e.target.value || undefined;
				if (isNaN(value) || value === '' || value < 1) {
					value = undefined;
				} else if (value > Int32MaxValue) {
					value = Int32MaxValue;
				}
				let newQuantities = [...quantities];
				newQuantities[cell.row.id] = value ? parseInt(value) : value;
				setQuantities(newQuantities);
				break;

			case 'itemCode':
				value = e.target.value;
				const newState: ArticleProps[] = [...articles];
				newState[cell.row.id].itemCode = value;
				setArticles(newState);
				break;

			default:
				break;
		}
	};

	const handleRowInputBlur = async (cell: any) => {
		if (typeof cell?.value !== 'undefined' && cell?.value !== '') {
			let codesAndQuantity =
				articles &&
				articles.map((item: ArticleProps, index: number) => {
					return {
						code: item.itemCode as string,
						quantity: quantities[index] as number,
					};
				});
			if (codesAndQuantity?.length > 0) {
				setIsValidating(true);
				try {
					const data = await quickAddValidateCode(
						api.validateCodeUrl,
						codesAndQuantity
					);
					setIsValidating(false);
					setArticles(data || []);
				} catch (error) {
					setIsValidating(false);
					console.log(error);
				}
			}
		}
	};

	const handleKeyDown = (e: any) => {
		if (e.key === 'Enter' && !isValidating) {
			handleAddRow();
		}
	};

	const handleRemoveRow = (index: number) => {
		const newArticles = articles.filter((item, i) => {
			return i !== index;
		});
		const newQuantities = quantities.filter((item, i) => {
			return i !== index;
		});

		setArticles(newArticles);
		setQuantities(newQuantities);
	};

	const handleAddToCart = async () => {
		if (articles && articles.length > 0) {
			const validArticles = articles.filter((item) => item.isValid === true);
			if (validArticles.length > 0) {
				let articleList: AddToCartModel[] = [];

				validArticles.forEach((article) => {
					articleList.push({
						itemCode: article.itemCode!,
						quantity: article.quantity!,
					});
				});

				try {
					await dispatch(AddToCart('POST', articleList, sortSelection));
					toast(t('quickadd/transfersuccess'), {
						type: toast.TYPE.SUCCESS,
					});
				} catch (err) {
					if (err instanceof QuickImportArticlesError) {
						toast(t('quickadd/transferwarning'), { type: toast.TYPE.ERROR });
					} else if (err instanceof ServerError) {
						toast(t('quickadd/transfererror'), { type: toast.TYPE.ERROR });
					} else if (err instanceof GenericWarning) {
						toast(err.message, { type: toast.TYPE.WARNING });
					}
				} finally {
					setArticles([]);
					setQuantities([]);
					onToggle();
				}
			}
		}
	};

	//Focus new row when created.
	useEffect(() => {
		if (newRowAdded) {
			const newInput = document.querySelector(
				`#rowinput-${data.length - 1}-itemCode`
			);
			(newInput as HTMLElement)?.focus();
			setNewRowAdded(false);
		}
		//eslint-disable-next-line
	}, [newRowAdded]);

	return (
		<Modal
			toggleModal={() => onToggle && onToggle()}
			heading={t('quickadd/importheading')}
			title={t('quickadd/importheading')}
			width="max"
		>
			<div>
				<label htmlFor={'pasteinput'} className="text-p block m-0">
					{t('quickadd/importdescription')}
				</label>
				<input
					id="pasteinput"
					name="pasteinput"
					value={inputValue}
					onBlur={() => clearInput()}
					onChange={() => {}}
					onPaste={handlePaste}
					placeholder={t('quickadd/importpasteplaceholder')}
					className="text-p py-2 px-4 my-4 w-full md:w-6/12 h-10 rounded border border-greyDark"
				/>
			</div>
			<div>
				<table className="w-full quickimporttable" {...getTableProps()}>
					<caption></caption>
					<thead className="bg-blue">
						{headerGroups.map((headerGroup: any, index: number) => (
							<tr
								key={index}
								className="text-white text-p p-2"
								{...headerGroup.getHeaderGroupProps()}
							>
								<th className="text-sm p-1 max-w-16 md:max-w-25 lg:max-w-none md:text-p md:py-2 md:px-4 font-medium text-center whitespace-no-wrap overflow-hidden overflow-ellipsis">
									{t('quickadd/remove')}
								</th>

								{headerGroup.headers.map((column: any, index: number) => (
									<th
										key={index}
										{...column.getHeaderProps()}
										id={`header-${index}`}
										className="text-sm p-1 text-left max-w-16 md:max-w-25 lg:max-w-none md:text-p md:py-2 md:px-4 font-medium whitespace-no-wrap overflow-hidden overflow-ellipsis"
									>
										{column.render('Header')}
									</th>
								))}

								<th className="text-sm p-1 max-w-16 md:max-w-25 lg:max-w-none md:text-p md:py-2 md:px-4 font-medium text-center whitespace-no-wrap overflow-hidden overflow-ellipsis">
									{t('quickadd/status')}
								</th>
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{rows.map((row: any, index: number) => {
							prepareRow(row);
							return (
								<tr
									className={clsx(
										row.original.isValid ? 'row-valid' : 'row-inValid',
										'border-b border-white'
									)}
									key={index}
								>
									<td className="py-2 px-4">
										<button
											className="bg-transparent w-full"
											onClick={() => handleRemoveRow(index)}
										>
											<Icon
												icon="clear"
												aria-label={t('quickadd/removerow')}
												color="#949494"
											/>
										</button>
									</td>
									{row.cells.map((cell: any, index: number) => {
										return (
											<td {...cell.getCellProps()}>
												{cell.column.id === 'quantity' ? (
													<input
														type="number"
														id={`rowinput-${row.id}-${cell.column.id}`}
														className="text-p py-2 px-4 w-full bg-transparent rounded border-none"
														aria-labelledby={`header-${index}`}
														value={quantities[row.id] || ''}
														onChange={(e) => handleInputChange(e, cell)}
														onKeyDown={handleKeyDown}
													/>
												) : cell.column.id === 'itemCode' ? (
													<input
														type="text"
														id={`rowinput-${row.id}-${cell.column.id}`}
														className="text-p py-2 px-4 w-full bg-transparent rounded border-none"
														aria-labelledby={`header-${index}`}
														value={cell.value || ''}
														onChange={(e) => handleInputChange(e, cell)}
														onBlur={() => handleRowInputBlur(cell)}
														onKeyDown={handleKeyDown}
													/>
												) : (
													<span className="text-p py-2 px-4">
														{cell.render('Cell')}
													</span>
												)}
											</td>
										);
									})}

									<td className="text-center py-2 px-4">
										{isValidating ? (
											<Icon icon="loader" animate="spin" />
										) : (
											<Tooltip
												title={`${t('quickadd/statusariatitle')} ${t(
													'quickadd/row'
												)} ${row.id}`}
												icon={
													row.original.isValid ? (
														<Icon icon="checkCircle" color="#00B900" />
													) : (
														<Icon icon="alertTriangle" color="#D0021B" />
													)
												}
											>
												{row.original.isValid
													? t('quickadd/validarticletooltip')
													: t('quickadd/invalidarticletooltip')}
											</Tooltip>
										)}
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
				<Button buttonColor="grey" type="button" onClick={handleAddRow}>
					<Icon icon="plus" className="mr-2.5 ml-0 align-text-top" />
					{t('quickadd/addrow')}
				</Button>
				<Button
					buttonColor="blue"
					type="button"
					className="ml-4"
					onClick={handleAddToCart}
				>
					{t('quickadd/transfertocart')}
				</Button>
			</div>
		</Modal>
	);
};

export default QuickImportArticlesModal;
