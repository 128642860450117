import plus from './IconPlus';
import minus from './IconMinus';
import arrow from './IconArrow';
import menu from './IconMenu';
import search from './IconSearch';
import switchLink from './IconSwitchLink';
import externalLink from './IconExternalLink';
import externalLink2 from './IconColumnlink';
import chevron from './IconChevron';
import chevrons from './IconChevrons';
import clear from './IconClear';
import twitter from './IconTwitter';
import twitterX from './IconTwitterX';
import facebook from './IconFacebook';
import linkedin from './IconLinkedin';
import youtube from './IconYoutube';
import close from './IconClose';
import question from './IconQuestion';
import loader from './IconLoader';
import zoomIn from './IconZoomIn';
import print from './IconPrint';
import mail from './IconMail';
import sliders from './IconSliders';
import download from './IconDownload';
import copy from './IconCopy';
import square from './IconSquare';
import cart from './IconCart';
import calendarPlus from './IconCalendarPlus';
import file from './IconFile';
import checkSquare from './IconCheckSquare';
import camera from './IconCamera';
import tag from './IconTag';
import home from './IconHome';
import box from './IconBox';
import truck from './IconTruck';
import trash from './IconTrash';
import save from './IconSave';
import redo from './IconRedo';
import checkCircle from './IconCheckCircle';
import alertTriangle from './IconAlertTriangle';
import check from './IconCheck';
import shoppingCart from './IconShoppingCart';
import user from './IconUser';
import archive from './IconArchive';
import mapPin from './IconMapPin';
import sortAscending from './IconSortAscending';
import sortDescending from './IconSortDescending';
import edit from './IconEdit';
import calendar from './IconCalendar';
import lock from './IconLock';
import filter from './IconFilter';
import toggleDesktop from './IconToggleDesktop';
import toggleMobile from './IconToggleMobile';
import clock from './IconClock';

export default {
	plus,
	minus,
	arrow,
	menu,
	search,
	switchLink,
	externalLink,
	externalLink2,
	chevron,
	chevrons,
	clear,
	twitter,
	twitterX,
	facebook,
	linkedin,
	youtube,
	close,
	question,
	loader,
	zoomIn,
	print,
	mail,
	sliders,
	download,
	copy,
	square,
	checkSquare,
	cart,
	calendarPlus,
	file,
	camera,
	tag,
	home,
	box,
	truck,
	trash,
	save,
	redo,
	checkCircle,
	alertTriangle,
	check,
	user,
	archive,
	mapPin,
	shoppingCart,
	sortAscending,
	sortDescending,
	edit,
	calendar,
	lock,
	filter,
	toggleDesktop,
	toggleMobile,
	clock,
};
