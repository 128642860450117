/**
 * Icon
 */
import icons from './icons';
import clsx from 'clsx';

export type iconTypes =
	| 'plus'
	| 'minus'
	| 'arrow'
	| 'menu'
	| 'search'
	| 'switchLink'
	| 'externalLink'
	| 'externalLink2'
	| 'chevron'
	| 'chevrons'
	| 'clear'
	| 'close'
	| 'twitter'
	| 'twitterX'
	| 'facebook'
	| 'youtube'
	| 'linkedin'
	| 'question'
	| 'loader'
	| 'zoomIn'
	| 'print'
	| 'mail'
	| 'sliders'
	| 'download'
	| 'square'
	| 'checkSquare'
	| 'cart'
	| 'calendarPlus'
	| 'file'
	| 'camera'
	| 'tag'
	| 'home'
	| 'box'
	| 'truck'
	| 'trash'
	| 'save'
	| 'copy'
	| 'checkCircle'
	| 'alertTriangle'
	| 'redo'
	| 'shoppingCart'
	| 'check'
	| 'user'
	| 'archive'
	| 'mapPin'
	| 'sortAscending'
	| 'sortDescending'
	| 'edit'
	| 'calendar'
	| 'filter'
	| 'toggleDesktop'
	| 'toggleMobile'
	| 'lock'
	| 'clock';

export interface IconList {
	/** The icon to be shown */
	icon: iconTypes;
}

type IconSizes = 0.75 | 1 | 1.25 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
type IconDirections = 'left' | 'up' | 'right' | 'down';

interface Props extends IconList {
	/** Set the size of the icon. <br/>1 = 24px<br/> 2 = 32px<br/> 3 = 40px <br/> 4 = 48px*/
	size?: IconSizes;

	/** Set the color of the icon */
	color?: string;

	/** Set the direction of the icon. */
	direction?: IconDirections;

	/** Animate the icon. */
	animate?: 'spin';

	/** Pass optional classnames for the html */
	className?: string;

	/** Do not fill current */
	fillCurrent?: boolean;

	[htmlAttributes: string]: any;
}

const getSize = (size: IconSizes) => {
	switch (size) {
		case 0.75:
			return 'w-3 h-3';
		case 1:
			return 'w-4 h-4';
		case 1.25:
			return 'w-5 h-5';
		case 2:
			return 'w-6 h-6';
		case 3:
			return 'w-8 h-8';
		case 4:
			return 'w-10 h-10';
		case 5:
			return 'w-12 h-12';
		case 6:
			return 'w-14 h-14';
		case 7:
			return 'w-16 h-16';
		case 8:
			return 'w-18 h-18';
		case 9:
			return 'w-20 h-20';
	}
};

const getRotation = (direction: IconDirections) => {
	switch (direction) {
		case 'up':
			return '-rotate-90';
		case 'right':
			return 'rotate-0';
		case 'down':
			return 'rotate-90';
		case 'left':
			return 'rotate-180';
	}
};

/** This is the icon component that handles all the icons in the project. <br /> The user will be able to set the <strong>color</strong>, <strong>size</strong>, <strong>direction</strong> and <strong>animation</strong> for all the icons. */
const Icon = ({
	icon,
	size = 1,
	direction = 'right',
	animate = undefined,
	className,
	expanded,
	fillCurrent,
	...htmlAttributes
}: Props): JSX.Element => {
	const IconSvg = icons[icon];

	return (
		<IconSvg
			className={clsx(
				'inline-block',
				'align-middle',
				'transition-all',
				'duration-300',
				!animate && 'transform',
				fillCurrent && 'fill-current',
				getSize(size),
				getRotation(direction),
				animate && 'animate-spin-slow',
				className
			)}
			{...htmlAttributes}
		/>
	);
};

export default Icon;
